<template>
  <section>
    <div class="max-w-6xl pb-24 mx-auto px-4 sm:px-6">
      <div class="">

        <!-- <div class="max-w-sm mx-auto grid gap-6 md:grid-cols-2 lg:grid-cols-2 items-start md:max-w-2xl lg:max-w-none">
          Hamarosan érkeznek a referenciák.
        </div> -->

        <!-- Items -->
        <!-- <div class="max-w-sm mx-auto grid gap-6 md:grid-cols-2 lg:grid-cols-2 items-start md:max-w-2xl lg:max-w-none">

          <template v-for="(ref, i) in reference">
            <div :key="i" class="relative flex flex-col items-center p-6 bg-white rounded shadow-lg" data-aos="zoom-y-out">
              <img class="w-5/12 mb-4 reference-logo" :src="require(`@/images/tc/reference/${ref.logo}`)" :alt="`${ref.company} logo`" />
              <p class="text-gray-600 text-center">{{ref.desc}}</p>
            </div>
          </template>

        </div>

        <p class="text-center mt-12 text-gray-500 font-bold">A STOVKY DALŠÍCH...</p> -->
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'AboutIntro',
  data() {
    return {
      reference: [
        {
          company: 'STRABAG a.s.',
          desc: 'STRABAG a.s. je součástí technologického stavebního koncernu STRABAG a patří k předním stavebním společnostem v České republice. Realizuje všechny druhy staveb v odvětvích dopravního, pozemního a inženýrského stavitelství.',
          logo: 'strabag.png'
        },
        {
          company: 'ZLínský kraj',
          desc: 'Zlínský kraj je oblíbenou turistickou oblastí, jeho atraktivita vyplývá z množství přírodních, kulturních a historických památek. Zároveň je líhní investičních projektů, které Zlínský kraj jako investor realizuje za použití CDE Trimble Connect.',
          logo: 'zlin.png'
        },
        {
          company: 'Metrostav Slovakia a.s.',
          desc: 'Metrostav Slovakia a.s. je dceřinou společností českého Metrostavu, který samostatně působí na celem území Slovenské republiky. Ve svém širokém portfóliu má velké množství úspěšně zrealizovaných projektů ze všech oblastí stavebních činností.',
          logo: 'metrostav.png'
        },
        {
          company: 'AFRY CZ',
          desc: 'AFRY CZ je moderní projektová, inženýrská a konzultační firma. Předností jsou skvělí lidé, inovativní přístup a otevřená komunikace. Od roku 2010 je členem mezinárodní skupiny ÅF Pöyry s více než 16 000 odborníky po celém světě.',
          logo: 'afry.png'
        },
        {
          company: 'AQUA PROCON s.r.o.',
          desc: 'AQUA PROCON s.r.o. je přední, ryze česká projektová a inženýrská společnost s mezinárodní působností v oblasti vodního hospodářství. Cílem společnosti je poskytovat projektové a inženýrské služby v oblasti vodohospodářských staveb.',
          logo: 'aquaprocon.png'
        },
        {
          company: 'ALIAZ - ocelové konstrukce, spol. s r.o.',
          desc: 'Hlavní činností společnosti ALIAZ - ocelové konstrukce, spol. s r.o. je výroba, montáž a opláštění atypických ocelových konstrukcí se zvláštním zaměřením na výrobní a sportovní objekty, autosalony, logistická centra, nákupní zóny a jiné.',
          logo: 'aliaz.png'
        },
        {
          company: 'VolkerWessel',
          desc: 'VolkerWessel je celosvětový lídr mezi stavebními společnostmi sídlící v Nizozemí. Zaměstnává na 15 000 zaměstnanců ve více než 100 dceřiných a partnerských společnostech v Nizozemsku, Belgii, Kanadě, Spojeném království apod.',
          logo: 'vw.jpg'
        },
        {
          company: 'COLAR',
          desc: 'COLAR sa od svojho vzniku v roku 1999 špecializuje na výrobu a montáž oceľových konštrukcií.Prevažne sa jedná o nosné, technologické, trubkové konštrukcie, konštrukcie oceľových hál, striech a rôzne atypické konštrukcie podľa dohody s investormi.',
          logo: 'colar.png'
        },
        {
          company: 'Moravskoslezský kraj logo',
          desc: 'Kraj je vlastníkem nemovitého majetku v hodnotě přesahující 44mld. Kč. Majetek je užíván krajem či příspěvkovými organizacemi pro různé činnosti v odvětvích krizového řízení, dopravy a silničního hospodářství, zdravotnictví apod.',
          logo: 'moravskoslezskykraj.png'
        },
        {
          company: 'Voestalpine High Performance Metals CZ',
          desc: 'Voestalpine High Performance Metals CZ představuje prodejní společnost skupiny voestalpine. Divize se zaměřuje na technologicky náročné segmenty výrobků a je světovým lídrem v oblasti nástrojové oceli a dalších speciálních ocelí.',
          logo: 'voestalpine.png'
        },
        {
          company: 'Hutní Montáže',
          desc: 'Tradiční firma v segmentu montáži, oprav a údržby průmyslové a energetické infrastruktury. Společnost se podílela například na budování bratislavského mostu SNP, rekonstrukci tepelné elektrárny Tušimice nebo montovala most Apollo přes řeku Dunaj.',
          logo: 'hutnimontaze.png'
        },
        {
          company: 'Allcons',
          desc: 'Společnost poskytuje komplexní služby v oboru projekce a realizace ocelových, betonových a jiných stavebních konstrukcí zejména v oblasti hutní výroby, energetiky, chemického a ocelárenského průmyslu, ale také občanských staveb.',
          logo: 'allcons.svg'
        },
        {
          company: 'Femont Opava',
          desc: 'Společnost Femont je dynamická a flexibilní společnost, která působí na trhu již od roku 1992. Zabývá se výrobou a montážemi veškerých ocelových konstrukcí a hal včetně všech dodávek jako generální dodavatel staveb.',
          logo: 'femont-opava.png'
        },
        {
          company: 'Lambda Studio',
          desc: 'Lambda Studio zajišťuje projekční služby ve fázích od studie proveditelnosti až po dokumentaci skutečného provedení stavby, vč. autorského nebo technického dozoru a to zejména v oborech TZB a elektrotechnika.',
          logo: 'lambda.png'
        },
        {
          company: 'Doprastav, a.s.',
          desc: 'Doprastav, a.s. realizuje stavby na celém území Slovenska, ale také v zahraničí. Předmětem činnosti společnosti je komplexní dodávka stavebních objektů a prací v segmentech inženýrského, pozemního, podzemního a vodohospodářského stavitelství.',
          logo: 'doprastav.png'
        },
        {
          company: 'G4D s.r.o.',
          desc: 'Společnost G4D s.r.o. se specializuje na komplexní služby sběru a zpracování 3D dat s využitím nejmodernějších metod 3D laserového skenování a letecké bezpilotní fotogrammetrie. Tvoří 3D modely objektů pro využití v CAD systémech.',
          logo: 'g4d.png'
        },
        {
          company: 'Energy Benefit Centre',
          desc: 'Energy Benefit Centre je lídrem na trhu v oblasti návrhu pasivních budov, úsporných technických zařízení a obnovitelných zdrojů energie pro budovy. Zkušení odborníci navrhují novostavby i rekonstrukce středních a větších staveb.',
          logo: 'energybenefit.svg'
        },
        {
          company: 'Jihomoravský kraj',
          desc: 'Jihomoravský kraj patří k regionům s výrazným ekonomickým potenciálem. Zejména v posledních letech roste počet podnikatelských subjektů v oblasti počítačové technologie, telekomunikací, vývoje softwaru a ostatních hi-tech oborů.',
          logo: 'jmk.svg'
        },
        {
          company: 'SIEBERT + TALAŠ, spol. s r.o.',
          desc: 'Odvážné nápady dosahující nových architektonických výšek a chytrá řešení, která zlepšují každodenní život. Od svého vzniku SIEBERT + TALAŠ pracovali na širokém spektru malých i velkých projektů v několika oblastech.',
          logo: 'siebert.svg'
        },
        {
          company: 'Egoé plus',
          desc: 'V Egoe chtějí dát věcem takovou podobu a kvalitu, aby si je pořizovali ideálně na celý život. Nechtějí vyrábět zbytečné produkty a vyvolávat umělou poptávku. Nadčasový design, pokorný a čistý, který neruší, ale vyvolává dobré emoce.',
          logo: 'egoe.svg'
        },
        {
          company: 'České vysoké učení technické v Praze',
          desc: 'ČVUT je významná česká technická univerzita sídlící převážně v Praze, patří k největším a nejstarším technickým vysokým školám v Evropě. V současné době má ČVUT osm fakult a studuje na něm přes 17 tisíc studentů.',
          logo: 'cvut.svg'
        },
        {
          company: 'LOXIA Architects Engineers',
          desc: 'Ideou LOXIA je vášeň pro promyšlenou a zodpovědnou architekturu. Jejich vášní jsou reálné stavby. Stavby, které stojí a fungují. Stavby, které nejsou pouze na papíře, ale naopak stavby, které zde vydrží po staletí.',
          logo: 'loxia.webp'
        },
        {
          company: 'Vysoká Škola Báňská - technická Univerzita Ostrava',
          desc: 'Už více než 170 let propojuje technické, ekonomické a přírodovědné obory v moderních studijních programech, reagujících na skutečné problémy současnosti. Realizují základní i aplikovaný výzkum na špičkové úrovni.',
          logo: 'vsb.svg'
        },
        {
          company: 'GEODROM s.r.o.',
          desc: 'GEODROM je společnost, která nabízí kompletní služby v oblasti sběru a vyhodnocení 2D/3D dat. Zabývají se realizací projektů v oblasti inženýrské geodézie, katastru nemovitostí, mobilního mapování a mnoho dalších.',
          logo: 'geodrom.png'
        }
      ]
    }
  },
}
</script>

<style>
.reference-logo{
  height: 50px;
  width: auto;
  max-height: 50px;
  max-width: 200px;
}
</style>