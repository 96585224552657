<template>
  <div class="flex flex-col min-h-screen overflow-hidden">

    <!-- Site header -->
    <Header />

    <!-- Page content -->
    <main class="flex-grow">
      <!-- Page sections -->
      <ReferenceIntro />
      <ReferenceInfo />
      <Cta />
    </main>

    <!-- Site footer -->
    <Footer />

  </div>
</template>

<script>
import Header from './../partials/Header.vue'
import ReferenceIntro from './../partials/ReferenceIntro.vue'
import ReferenceInfo from './../partials/ReferenceInfo.vue'
import Cta from './../partials/Cta.vue'
import Footer from './../partials/Footer.vue'

export default {
  name: 'Reference',
  title: 'Referencia',
  components: {
    Header,
    ReferenceIntro,
    ReferenceInfo,
    Cta,
    Footer,
  },
}
</script>
