<template>
  <header class="fixed w-full z-30 md:bg-opacity-90 transition duration-300 ease-in-out" :class="{ 'bg-white blur shadow-lg': !top }">
    <div class="max-w-6xl mx-auto px-4 sm:px-6">
      <div class="flex items-center justify-between h-16 md:h-20">

        <!-- Site branding -->
        <div class="flex-shrink-0 mr-4">
          <!-- Logo -->
          <router-link to="/" class="block" aria-label="Cruip">
            <img class="w-8 h-8" :src="require('@/images/tc/mark.svg')" alt="Logo" />
          </router-link>
        </div>

        <!-- Desktop navigation -->
        <nav class="hidden md:flex md:flex-grow">

          <!-- Desktop menu links -->
          <ul class="flex flex-grow justify-end flex-wrap items-center">
            <li>
              <router-link to="/miert-trimble-connect" class="text-gray-600 hover:text-gray-900 px-3 lg:px-5 py-2 flex items-center transition duration-150 ease-in-out">Miért a Trimble Connect?</router-link>
            </li>

            <li>
              <router-link to="/egyuttmukodes" class="text-gray-600 hover:text-gray-900 px-3 lg:px-5 py-2 flex items-center transition duration-150 ease-in-out">Együttműködés</router-link>
            </li>

            <li>
              <router-link to="/arak" class="text-gray-600 hover:text-gray-900 px-3 lg:px-5 py-2 flex items-center transition duration-150 ease-in-out">Árak</router-link>
            </li>

            <!-- <li>
              <router-link to="/faq" class="text-gray-600 hover:text-gray-900 px-3 lg:px-5 py-2 flex items-center transition duration-150 ease-in-out">GYIK</router-link>
            </li> -->

            <li>
              <router-link to="/referencia" class="text-gray-600 hover:text-gray-900 px-3 lg:px-5 py-2 flex items-center transition duration-150 ease-in-out">Referencia</router-link>
            </li>

            <li>
              <router-link to="/kapcsolat" class="text-gray-600 hover:text-gray-900 px-3 lg:px-5 py-2 flex items-center transition duration-150 ease-in-out">Kapcsolat</router-link>
            </li>
          </ul>

          <!-- Desktop sign in links -->
          <ul class="flex flex-grow justify-end flex-wrap items-center">
            <li>
              <router-link to="/demo" class="btn-sm text-white bg-blue-800 hover:bg-blue-700 ml-3">
                <span>Próbálja ki ingyen!</span>
                <svg class="w-3 h-3 fill-current text-white flex-shrink-0 ml-2 -mr-1" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z" fill-rule="nonzero" />
                </svg>
              </router-link>
            </li>

            <li>
              <a href="https://web.connect.trimble.com/" v-tooltip="'TC open'" class="btn-sm text-white bg-blue-800 hover:bg-blue-700 ml-3" style="height: 40px">
                <span style="line-height: 1.375">&nbsp;</span>
                <img class="w-3" :src="require('@/images/tc/svg/login.svg')" style="filter: invert(100%) sepia(49%) saturate(2%) hue-rotate(309deg) brightness(110%) contrast(100%);" alt="Facebook" />
              </a>
            </li>

          </ul>

        </nav>

        <!-- Mobile menu -->
        <div class="flex md:hidden">

          <!-- Hamburger button -->
          <button class="hamburger" ref="hamburger" :class="{ active: mobileNavOpen }" aria-controls="mobile-nav" :aria-expanded="mobileNavOpen" @click="mobileNavOpen = !mobileNavOpen">
            <span class="sr-only">Menu</span>
            <svg class="w-6 h-6 fill-current text-gray-900" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <rect y="4" width="24" height="2" />
              <rect y="11" width="24" height="2" />
              <rect y="18" width="24" height="2" />
            </svg>
          </button>

          <!-- Mobile navigation -->
          <transition
            enter-active-class="transition ease-out duration-200 transform"
            enter-class="opacity-0 -translate-y-2"
            enter-to-class="opacity-100 translate-y-0"
            leave-active-class="transition ease-out duration-200"
            leave-class="opacity-100"
            leave-to-class="opacity-0"
          >
            <nav
              id="mobile-nav"
              ref="mobileNav"
              v-show="mobileNavOpen"
              class="absolute top-full h-screen pb-16 z-20 left-0 w-full overflow-scroll bg-white transition-all duration-300 ease-in-out"
            >
              <ul class="px-5 py-2">
                <li>
                  <router-link to="/miert-trimble-connect" class="flex text-gray-600 hover:text-gray-900 py-2 transition-all duration-300">Miért Trimble Connect</router-link>
                </li>
                <li>
                  <router-link to="/egyuttmukodes" class="flex text-gray-600 hover:text-gray-900 py-2 transition-all duration-300">Együttműködés</router-link>
                </li>
                <li>
                  <router-link to="/arak" class="flex text-gray-600 hover:text-gray-900 py-2 transition-all duration-300">Árak</router-link>
                </li>
                <!-- <li>
                  <router-link to="/faq" class="flex text-gray-600 hover:text-gray-900 py-2 transition-all duration-300">GYIK</router-link>
                </li> -->
                <li>
                  <router-link to="/referencia" class="flex text-gray-600 hover:text-gray-900 py-2 transition-all duration-300">Referencia</router-link>
                </li>
                <li>
                  <router-link to="/kapcsolat" class="flex text-gray-600 hover:text-gray-900 py-2 transition-all duration-300">Kapcsolat</router-link>
                </li>
                <li>
                  <a href="https://web.connect.trimble.com/" target="_blank" class="flex font-medium w-full text-gray-600 hover:text-gray-900 pt-2 justify-center">TC open</a>
                </li>
                <li>
                  <router-link to="/signup" class="btn-sm text-gray-200 bg-blue-800 hover:bg-blue-700 w-full my-2">
                    <span>Próbálja ki ingyen!</span>
                    <svg class="w-3 h-3 fill-current text-white flex-shrink-0 ml-2 -mr-1" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z" fill="#fff" fill-rule="nonzero" />
                    </svg>
                  </router-link>
                </li>
              </ul>
            </nav>
          </transition>

        </div>

      </div>
    </div>
  </header>
</template>

<script>

export default {
  name: 'Header',
  components: {
  },
  data: function () {
    return {
      mobileNavOpen: false,
      top: true
    }
  },
  methods: {
    clickOutside(e) {
      if (!this.mobileNavOpen || this.$refs.mobileNav.contains(e.target) || this.$refs.hamburger.contains(e.target)) return
      this.mobileNavOpen = false
    },
    keyPress() {
      if (!this.mobileNavOpen || event.keyCode !== 27) return
      this.mobileNavOpen = false
    },
    handleScroll() {
      this.top = window.pageYOffset > 10 ? false : true
    }
  },
  mounted() {
    document.addEventListener('click', this.clickOutside)
    document.addEventListener('keydown', this.keyPress)
    document.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy() {
    document.removeEventListener('click', this.clickOutside)
    document.removeEventListener('keydown', this.keyPress)
    document.removeEventListener('scroll', this.handleScroll)
  }
}
</script>

<style>
.tooltip .tooltip-inner {
  background: #009AD9!important;
  color: white;
  border-radius: 1rem;
  padding: 20px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.tooltip .tooltip-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 5px;
  border-color: #009AD9!important;
  z-index: 1;
}
</style>
