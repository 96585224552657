<template>
  <footer>
    <div class="max-w-6xl mx-auto px-4 sm:px-6">

      <!-- Top area: Blocks -->
      <div class="grid sm:grid-cols-12 gap-8 py-8 md:py-12 border-t border-gray-200">

        <!-- 1st block -->
        <div class="sm:col-span-12 lg:col-span-3">
          <div class="mb-2">
            <!-- Logo -->
            <router-link to="/" class="inline-block" aria-label="Trimble Connect">
              <img class="w-8 h-8" :src="require('@/images/tc/mark.svg')" alt="Logo" />
            </router-link>
          </div>
          <div class="text-sm text-gray-600">
            <a href="https://www.construsoft.com/hu/privacy-statement" target="_blank" class="text-gray-600 hover:text-gray-900 hover:underline transition duration-150 ease-in-out">Adatvédelmi nyilatkozat</a> <br> <a href="https://www.construsoft.com/hu/general-terms" target="_blank" class="text-gray-600 hover:text-gray-900 hover:underline transition duration-150 ease-in-out">Általános szerződési feltételek</a>
          </div>
        </div>

        <!-- 2nd block -->
        <div class="sm:col-span-6 md:col-span-3 lg:col-span-3">
          <h6 class="text-gray-800 font-medium mb-2">Trimble Connect</h6>
          <ul class="text-sm">
            <li class="mb-2">
              <router-link to="/miert-trimble-connect" class="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out">Miért Trimble Connect</router-link>
            </li>
            <li class="mb-2">
              <router-link to="/egyuttmukodes" class="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out">Együttműködés</router-link>
            </li>
            <li class="mb-2">
              <router-link to="/arak" class="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out">Árak</router-link>
            </li>
          </ul>
        </div>

        <!-- 3rd block -->
        <div class="sm:col-span-6 md:col-span-3 lg:col-span-3">
          <h6 class="text-gray-800 font-medium mb-2">Támogatás</h6>
          <ul class="text-sm">
            <!-- <li class="mb-2">
              <router-link to="/faq" class="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out">Gyakran ismételt kérdések</router-link>
            </li> -->
            <li class="mb-2">
              <router-link to="/referencia" class="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out">Hivatkozások</router-link>
            </li>
            <li class="mb-2">
              <router-link to="/kapcsolat" class="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out">Kapcsolat</router-link>
            </li>
          </ul>
        </div>

        <!-- 4th block -->
        <div class="sm:col-span-6 md:col-span-3 lg:col-span-3">
          <h6 class="text-gray-800 font-medium mb-2">Construsoft</h6>
          <ul class="text-sm">
            <li class="mb-2">
              <a href="https://www.construsoft.com/hu/magunkrol" target="_blank" class="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out">Rólunk</a>
            </li>
            <li class="mb-2">
              <a href="https://www.construsoft.com/hu/" target="_blank" class="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out">Honlap</a>
            </li>
            <li class="mb-2">
              <a href="https://www.construsoft.com/hu/contact" target="_blank" class="text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out">Kapcsolat</a>
            </li>
          </ul>
        </div>

        <!-- 5th block -->
<!--        <div class="sm:col-span-6 md:col-span-3 lg:col-span-3">-->
<!--          <h6 class="text-gray-800 font-medium mb-2">Hírlevél</h6>-->
<!--          <p class="text-sm text-gray-600 mb-4">Iratkozzon fel hírlevelünkre</p>-->
<!--          <iframe id="newsletterFormFrame" name="newsletterFormFrame" style="display:none;"></iframe>-->

<!--          <form id="newsletterForm" target="newsletterFormFrame" action="https://marketing.construsoft.com/acton/eform/36418/efcbf63f-5183-4980-a7ff-ef18a29816cd/d-ext-0001">-->
<!--            <div class="relative flex items-center max-w-xs">-->
<!--              <input type="email" class="form-input w-full text-gray-800 px-3 py-2 pr-12 text-sm" placeholder="Email" v-model="email" name="email" id="email" />-->
<!--              <button @click="newsletter()" class="absolute inset-0 left-auto">-->
<!--                <span class="absolute inset-0 right-auto w-px -ml-px my-2 bg-gray-300" aria-hidden="true"></span>-->
<!--                      <svg class="w-3 h-3 fill-current text-blue-800 mx-3 flex-shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">-->
<!--                        <path d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z" fill-rule="nonzero" />-->
<!--                      </svg>-->
<!--              </button>-->
<!--            </div>-->
<!--          </form>-->
<!--          <p class="text-green-600 text-sm" v-if="feedback">{{feedback}}</p>-->
<!--        </div>-->

      </div>

      <!-- Bottom area -->
      <div class="md:flex md:items-center md:justify-between py-4 md:py-8 border-t border-gray-200">

        <!-- Social links -->
        <ul class="flex mb-4 md:order-1 md:ml-4 md:mb-0">
          <li>
            <a href="https://www.facebook.com/ConstrusoftHungary" target="_blank" class="flex justify-center items-center" aria-label="Facebook">
              <img class="w-5 h-5" :src="require('@/images/tc/svg/fb-f.svg')" style="opacity: .4" alt="Facebook" />
            </a>
          </li>
          <li class="ml-4">
            <a href="https://www.linkedin.com/company/construsoft/" target="_blank" class="flex justify-center items-center" aria-label="LinkedIn">
              <img class="w-5 h-5" :src="require('@/images/tc/svg/in.svg')" style="opacity: .4" alt="LinkedIn" />
            </a>
          </li>
          <li class="ml-4">
            <a href="https://www.youtube.com/user/Construsoft" target="_blank" class="flex justify-center items-center" aria-label="YouTube">
              <img class="w-5 h-5" :src="require('@/images/tc/svg/yt.svg')" style="opacity: .4" alt="YouTube" />
            </a>
          </li>
        </ul>

        <!-- Copyrights note -->
        <div class="text-sm text-gray-600 mr-4">&copy; 2022 Construsoft</div>

      </div>

    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  data: function(){
    return{
      feedback: null,
      email: null
    }
  },
  methods: {
    newsletter(){
      if(this.email){
        document.getElementById("newsletterForm").submit()
        this.feedback = "Köszönöm!"
      }
    }
  }
}
</script>
