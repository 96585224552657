<template>
  <section>
    <div class="max-w-6xl mx-auto px-4 sm:px-6">
      <div class="pt-32 md:pt-40">

        <!-- Section header -->
        <div class="max-w-6xl mx-auto text-left">
          <h1 class="h1 mb-4">Referencia</h1>
          <p class="text-xl text-gray-600">
            Hamarosan érkeznek a referenciák.
            <!-- Vybraný výčet našich spokojených českých a slovenských zákazníků. Chtěli byste zde vaši společnost? <router-link class="underline" to="/kontakt">Napište nám!</router-link> -->
          </p>
        </div>

        <!-- <div class="relative flex flex-col items-center mb-6 md:mb-12 mt-6 md:mt-12 bg-blue-800 text-white font-bold p-6 rounded" data-aos="zoom-y-out">
            <p>Trimble Connect využívá již více než 10 milionů spokojených uživatelů po celém světě 🥳  </p>
        </div> -->

      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ReferenceIntro',
}
</script>